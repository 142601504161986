<template>
    <!--footer start-->
    <footer class="footer bgcolor-zenith-blue widget-footer clearfix" id="footer">
        <div class="first-footer">
            <div class="container">
                <div class="row align-items-center">
                    <div class="col-xs-12 col-sm-12 col-md-12 col-lg-6 widget-area">
                        <h4>Inscrivez-vous à notre newsletter</h4>
                        <p></p>
                    </div>
                    <div class="col-xs-12 col-sm-12 col-md-12 col-lg-6 widget-area">
                        <form id="subscribe-form" class="newsletter-form" method="post" action="#" @submit.prevent="submit()">
                            <div class="mailchimp-inputbox clearfix" id="subscribe-content"> 
                                <p><input type="email" name="email" placeholder="Entrez votre adresse e-mail..." required="" v-model="newsletter.email"></p>
                                <p><button class="submit ttm-btn ttm-btn-size-md ttm-btn-shape-rounded ttm-btn-style-fill ttm-btn-color-skincolor ttm-icon-btn-right" type="submit">S'abonner<i class="fa fa-angle-right"></i></button></p>
                            </div>
                            <div id="subscribe-msg"></div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
        <div class="second-footer">
            <div class="container">
                <div class="row">
                    <div class="col-xs-12 col-sm-6 col-md-4 col-lg-4 widget-area">
                        <div class="widget widget_text clearfix">
                            <h3 class="widget-title">Nos réseaux sociaux</h3>
                            <div class="textwidget widget-text">
                                <p></p>
                            </div>
                            <div class="social-icons circle mt-30">
                                <ul class="list-inline">
                                    <li class="social-facebook" v-if="websiteMoreInfo.facebook"><a class="tooltip-top" target="_blank" :href="websiteMoreInfo.facebook" data-tooltip="Facebook"><i class="fa fa-facebook"></i></a></li>
                                    <li class="social-twitter" v-if="websiteMoreInfo.twitter"><a class="tooltip-top" target="_blank" :href="websiteMoreInfo.twitter" data-tooltip="twitter"><i class="fa fa-twitter"></i></a></li>
                                </ul>
                            </div>
                        </div>
                    </div>
                    <div class="col-xs-12 col-sm-6 col-md-4 col-lg-4 widget-area">
                        <div class="widget widget_nav_menu clearfix">
                           <h3 class="widget-title">Nos services</h3>
                            <ul class="menu">
                                
                                <li v-for="(item, i) in serviceWidgetsData" :key="i">{{ item.title || '' }}</li>
                                
                            </ul>
                        </div>
                    </div>
                    <div class="col-xs-12 col-sm-6 col-md-4 col-lg-4 widget-area">
                        <div class="widget widget-timing clearfix">
                            <h3 class="widget-title">Informations de contact</h3>
                            <p>{{ websiteInfo.description }}</p>
                            <ul class="widget_contact_wrapper">
                                <li v-if="websiteOwnerInfo.phone"><i class="ttm-textcolor-skincolor fa fa-phone"></i>{{ websiteOwnerInfo.phone }}</li>
                                <li v-if="websiteOwnerInfo.email"><i class="ttm-textcolor-skincolor fa fa-envelope-o"></i>{{ websiteOwnerInfo.email }}</li>
                                <li v-if="websiteOwnerInfo.address"><i class="ttm-textcolor-skincolor fa fa-map-marker"></i><span v-html="websiteOwnerInfo.address"></span></li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="bottom-footer-text">
            <div class="container">
                <div class="row copyright">
                    <div class="col-md-12 text-center">
                        <span>&copy;&nbsp;{{ new Date().getFullYear() }}&nbsp; {{ websiteInfo.title }}</span>
                    </div>
                </div>
            </div>
        </div>
    </footer>
    <!--footer end-->

    <!--back-to-top start-->
    <a id="totop" href="#top">
        <i class="fa fa-angle-up"></i>
    </a>
    <!--back-to-top end-->
</template>

<script>
import InfoMixin from '@/mixins/info'
import MenuMixin from '@/mixins/menu'
import WidgetMixin from '@/mixins/widget'

import { getBaseUrl } from '@/utils/functions'

export default {
    name: 'Footer',
    mixins: [ InfoMixin, MenuMixin, WidgetMixin ],
    data() {
        return {
            newsletter: {
                email: ''
            }
        }
    },
    computed: {
        serviceWidgetsData(){

            return this.getWidgetsData('SERVICE_ITEM')

        }
    },
    methods: {
        getBaseUrl,
        submit(){

            this.$message.success('Abonnement réussi !')
            this.newsletter.email = ''

        }
    }
}
</script>