import { mapState, mapGetters } from 'vuex'

import { WebsiteInfo } from '@/utils/enums'

export default{
    data() {
        return {
            websiteInfo: {
                title: '',
                description: '',
                headerText: '',
                metaKeys: ''                                               
            },
            websitePicsInfo: {
                favicon: '',
                mainLogo: '',
                footerLogo: '',
                footerImage: ''                
            },
            websiteOwnerInfo: {
                name: '',
                address: '',
                phone: '',
                email: '',
                postalBank: '' 
            },
            websiteMoreInfo: {
                facebook: '',
                twitter: '',
                whatsapp: '',
                instagram: '',
                websiteUrl: ''                
            }
        }
    },
    watch:{
        websiteInfoData: {
            handler(val, oldVal) {
                
                this.fetchInfoMixinData()

            },
            immediate: true,
            deep: true
        }
    },
    computed: {
        ...mapState({
            websiteInfoData: state => state.websiteInfo.websiteInfo
        }),
        ...mapGetters({
            getWebsiteInfo: 'websiteInfo/getWebsiteInfoValue'          
        })        
    }, 
    methods: {
        fetchInfoMixinData(){

            this.websitePicsInfo.favicon = this.getWebsiteInfo(WebsiteInfo.FAVICON.NAME)
            this.websitePicsInfo.mainLogo = this.getWebsiteInfo(WebsiteInfo.MAIN_LOGO.NAME)
            this.websitePicsInfo.footerLogo = this.getWebsiteInfo(WebsiteInfo.FOOTER_LOGO.NAME)
            this.websitePicsInfo.footerImage = this.getWebsiteInfo(WebsiteInfo.FOOTER_IMAGE.NAME)
    
            this.websiteInfo.title = this.getWebsiteInfo(WebsiteInfo.TITLE.NAME)
            this.websiteInfo.description = this.getWebsiteInfo(WebsiteInfo.DESCRIPTION.NAME)
            this.websiteInfo.headerText = this.getWebsiteInfo(WebsiteInfo.HEADER_TEXT.NAME)
            this.websiteInfo.metaKeys = this.getWebsiteInfo(WebsiteInfo.META_KEYS.NAME).replace(';', ', ')
    
            this.websiteOwnerInfo.name = this.getWebsiteInfo(WebsiteInfo.NAME.NAME)
            this.websiteOwnerInfo.address = this.getWebsiteInfo(WebsiteInfo.ADDRESS.NAME)
            this.websiteOwnerInfo.phone = this.getWebsiteInfo(WebsiteInfo.PHONE.NAME)
            this.websiteOwnerInfo.email = this.getWebsiteInfo(WebsiteInfo.EMAIL.NAME)
            this.websiteOwnerInfo.postalBank = this.getWebsiteInfo(WebsiteInfo.POSTAL_BANK.NAME)
    
            this.websiteMoreInfo.facebook = this.getWebsiteInfo(WebsiteInfo.FACEBOOK.NAME)
            this.websiteMoreInfo.twitter = this.getWebsiteInfo(WebsiteInfo.TWITTER.NAME)
            this.websiteMoreInfo.whatsapp = this.getWebsiteInfo(WebsiteInfo.WHATSAPP.NAME)
            this.websiteMoreInfo.instagram = this.getWebsiteInfo(WebsiteInfo.INSTAGRAM.NAME)
            this.websiteMoreInfo.websiteUrl = this.getWebsiteInfo(WebsiteInfo.WEBSITE_URL.NAME) 
    
        }   
    }    
}