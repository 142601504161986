<template>
    <div class="site-main" id="projects">
        <!--portfolio-section-->
        <section class="ttm-row portfolio_2-section bg-img1 clearfix" style="padding-bottom: 0">
            <div class="container">
                <!-- row -->
                <div class="row">
                    <div class="col-lg-12">
                        <div class="section-title title-style-center_text">
                            <div class="title-header">
                                <h5>{{ projectWidgetData.uptitle || '' }}</h5>
                                <h2 class="title">{{ projectWidgetData.title || '' }}</h2>
                            </div>
                            <div class="heading-seperator"><span></span></div>
                        </div>
                    </div>
                    <div class="col-lg-12">
                        <el-tabs v-model="activeTab" stretch>
                            <el-tab-pane :label="item.title" :name="item.id" v-for="(item, i) in projectWidgetsData" :key="i">
                                <el-row :gutter="10">
                                    <el-col :xs="24" :sm="12" :md="6" :lg="6" :xl="6" v-for="(subItem, y) in previewWidgetsData.filter(p => p.project == item.id)" :key="y">
                                        <el-image 
                                            fit="cover"
                                            append-to-body
                                            :src="makeLink(subItem.image)"
                                            style="width: 100%; height: 350px"                            
                                            class="section-select-preview mt-10"                            
                                            :preview-src-list="[makeLink(subItem.image)]">
                                        </el-image>
                                    </el-col>
                                </el-row>
                            </el-tab-pane>                            
                        </el-tabs>
                    </div>
                </div><!-- row end -->
            </div>
        </section>
        <!--portfolio-section end-->
    </div>
</template>

<script>
import WidgetMixin from '@/mixins/widget'

export default {
    name: 'Projects',
    mixins: [ WidgetMixin ],
    data() {
        return {
            activeTab: null
        }
    },
    computed: {
        projectWidgetData(){

            return this.getWidgetData('PROJECTS_SECTION')

        },
        projectWidgetsData(){

            let items = this.getWidgetsData('PROJECT_ITEM')

            if (items.length && !this.activeTab) this.activeTab = items[0].id

            return items

        },
        previewWidgetsData(){

            return this.getWidgetsData('PROJECT_ITEM_PREVIEW')

        }
    }
}
</script>

<style scoped>
::v-deep(.el-tabs__item.is-active) {
    color: #fdab24;
}

::v-deep(.el-tabs__active-bar) {
    background-color: #fdab24;
}
</style>