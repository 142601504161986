export default {
    data() {
        return {
            currentPage: null
        }
    },
    created() {
        
        this.currentPage = this.homePage

    },
    computed: {
        homePage(){
            
            let pages = this.pages.filter(page => parseInt(page.is_home))

            return pages.length ? pages[0] : null

        }
    },
    methods: {
        sectionsArray(data){

            return data ? data.split(';') : []
        
        }
    },
}