<template>
    <div class="site-main" id="services">
        <!--services-section end-->
        <section class="ttm-row services-section bgcolor-zenith-blue clearfix">
            <div class="container-fluid">
                <!-- row -->
                <div class="row">
                    <div class="col-xl-11 m-auto">
                        <div class="row row-equal-height">
                            <div class="col-xl-4 col-lg-6 col-md-6 col-sm-6">
                                <div class="pt-30 text-left res-575-pt-15">
                                    <!-- section title -->
                                    <div class="section-title">
                                        <div class="title-header">
                                            <h5>{{ serviceWidgetData.uptitle || '' }}</h5>
                                            <h2 class="title">{{ serviceWidgetData.title || '' }}</h2>
                                        </div>
                                        <div class="heading-seperator"><span></span></div>
                                    </div><!-- section title end -->
                                    <p>{{ serviceWidgetData.text || '' }}</p>
                                </div>
                            </div>

                            <div class="col-xl-4 col-lg-6 col-md-6 col-sm-6" v-for="(item, i) in serviceWidgetsData" :key="i">
                                <!--featured-icon-box-->
                                <div class="featured-icon-box icon-align-top-content style2">
                                    <div class="featured-icon">
                                        <div class="ttm-icon ttm-icon_element-onlytxt ttm-icon_element-color-skincolor ttm-icon_element-size-lg">
                                            <font-awesome-icon :icon="item.icon || ''" size="3x"/>
                                        </div>
                                    </div>
                                    <div class="featured-content">
                                        <div class="featured-title">
                                            <h5>{{ item.title || '' }}</h5>
                                        </div>
                                        <div class="featured-desc">
                                            <p>{{ item.description || '' }}</p>
                                        </div>
                                    </div>
                                </div><!-- featured-icon-box end-->
                            </div>
                            
                        </div><!-- row end -->
                    </div>
                </div>
            </div>
        </section>
        <!--services-section end-->
    </div>
</template>

<script>
import WidgetMixin from '@/mixins/widget'

export default {
    name: 'Services',
    mixins: [ WidgetMixin ],
    computed: {
        serviceWidgetData(){

            return this.getWidgetData('SERVICES_SECTION')

        },
        serviceWidgetsData(){

            return this.getWidgetsData('SERVICE_ITEM')

        }
    }
}
</script>