<template>
    <!--header start-->
    <header id="masthead" class="header ttm-header-style-03">
        <div id="site-header-menu" class="site-header-menu ttm-bgcolor-darkgrey">
            <div class="site-header-menu-inner ttm-stickable-header" id="header">
                <div class="container-fluid">
                    <!--site-navigation -->
                    <div class="site-navigation d-flex flex-row align-items-center">
                        <!-- site-branding -->
                        <div class="site-branding">
                            <a class="home-link" href="#" title="Vemlo" rel="home">
                                <img id="logo-img" class="img-center" :src="makeLink(websitePicsInfo.mainLogo)" alt="logo-img" width="80">
                            </a>
                        </div><!-- site-branding end -->
                        <div class="btn-show-menu-mobile menubar menubar--squeeze">
                            <span class="menubar-box">
                                <span class="menubar-inner"></span>
                            </span>
                        </div>
                        <!-- menu -->
                        <nav class="main-menu menu-mobile" id="menu">
                            <ul class="menu">
                                <li class="mega-menu-item" :class="{'active' : (currentMenuItem && currentMenuItem.id == parentItem.id)}" v-for="parentItem in currentParentMenuItems" :key="parentItem.id">

                                    <a :href="parentItem.href" class="mega-menu-link" @click="setCurrentMenuItem(parentItem)" v-smooth-scroll>{{ parentItem.name }}</a>
                                    
                                    <ul class="mega-submenu" v-if="currentChildrenMenuItems(parentItem.id)">
                                        <li :class="{'active' : (currentMenuItem && currentMenuItem.id == childItem.id)}" v-for="childItem in currentChildrenMenuItems(parentItem.id)" :key="childItem.id">
                                     
                                            <a :href="childItem.href" @click="setCurrentMenuItem(childItem)" v-smooth-scroll>{{ childItem.name }}</a>

                                        </li>
                                    </ul>

                                </li>
                            </ul>
                        </nav>
                        <div class="header_extra d-flex flex-row align-items-center justify-content-end ml-auto">
                            <div class="header_social">
                                <ul class="social-icons">
                                    <li v-if="websiteMoreInfo.facebook">
                                        <a :href="websiteMoreInfo.facebook" target="_blank"><i class="fa fa-facebook"></i></a>
                                    </li>
                                    <li v-if="websiteMoreInfo.twitter">
                                        <a :href="websiteMoreInfo.twitter" target="_blank"><i class="fa fa-twitter"></i></a>
                                    </li>
                                </ul>
                            </div>
                            <div class="header_btn hide">
                                <a class="ttm-btn ttm-btn-size-sm ttm-btn-shape-rounded ttm-btn-style-border ttm-btn-color-white" :href="getBaseUrl() + '/btp'">BTP</a>
                            </div>
                            <div class="h_call d-flex align-items-center pl-1">
                                <div class="ttm-icon ttm-icon_element-fill ttm-icon_element-color-skincolor ttm-icon_element-size-xs ttm-icon_element-style-rounded mb-0">
                                    <i class="fa fa-phone"></i>
                                </div>
                                <div class="pl-2 ttm-textcolor-white" v-if="websiteOwnerInfo.phone">{{ websiteOwnerInfo.phone }}</div>
                            </div>
                        </div>
                    </div><!-- site-navigation end-->
                </div>
            </div>
        </div>
        <!-- site-header-menu end-->
    </header>
    <!--header end-->
</template>

<script>
import InfoMixin from '@/mixins/info'
import MenuMixin from '@/mixins/menu'

import { getBaseUrl } from '@/utils/functions'

export default {
    name: 'Header',
    mixins: [ InfoMixin, MenuMixin ],
    methods: {
        getBaseUrl
    }
}
</script>

<style scoped>
::v-deep(.site-branding img) {
    max-height: unset;
}

::v-deep(.ttm-bgcolor-darkgrey .ttm-stickable-header.fixed-header){
    background: white;
}

::v-deep(.ttm-bgcolor-darkgrey .ttm-stickable-header.fixed-header .menu .mega-menu-link){
    color: dimgray;
}

::v-deep(.ttm-bgcolor-darkgrey .ttm-stickable-header .menu .mega-menu-link){
    color: #006eb7;
}

::v-deep(.ttm-stickable-header.fixed-header .ttm-btn.ttm-btn-color-white.ttm-btn-style-border) {
    color: #fdab24;
    border-color: #fdab24;
    background-color: transparent;
}

::v-deep(.ttm-bgcolor-darkgrey .ttm-stickable-header.fixed-header a), 
::v-deep(.ttm-bgcolor-darkgrey .ttm-stickable-header.fixed-header i) {
    color: #fdab24;
}

::v-deep(.ttm-bgcolor-darkgrey .ttm-stickable-header a), 
::v-deep(.ttm-bgcolor-darkgrey .ttm-stickable-header i) {
    color: #006eb7;
}

::v-deep(#site-header-menu .site-navigation li.ttm-sepline) {
    display: none;
}

@media only screen and (max-width: 1199px){
  .site-header-menu.ttm-bgcolor-darkgrey{
    background-color: white;
  }

  .ttm-header-style-03 .menubar-inner, 
  .ttm-header-style-03 .menubar-inner:after, 
  .ttm-header-style-03 .menubar-inner:before {
    background-color: #fdab24;
  }
}
</style>