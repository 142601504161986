<template>
    <div class="site-main" id="contact">
        <!--- conatact-section -->
        <section class="ttm-row conatact-section clearfix" style="padding-bottom: 0">
            <div class="container">
                <!-- row -->
                <div class="row">
                    <div class="col-lg-7 col-md-10 mx-auto">
                        <!-- section title -->
                        <div class="section-title title-style-center_text">
                            <div class="title-header">
                                <h5></h5>
                                <h2 class="title">Contactez-nous</h2>
                            </div>
                            <div class="heading-seperator"><span></span></div>
                        </div><!-- section title end -->
                        <div class="pb-20 text-center">
                            <p>Pour une assistance, accompagnement, informations ou un devis, écrivez-nous</p>
                        </div>
                    </div>
                    <div class="col-lg-12">
                        <form id="request_qoute_form" class="request_qoute_form wrap-form clearfix" @submit.prevent="submit()">
                            <div class="row">
                                <div class="col-lg-8">
                                    <div class="row">
                                        <div class="col-md-6">
                                            <label>
                                                <span class="text-input"><input v-model="contactForm.name" name="name" type="text" placeholder="Nom" required="required"></span>
                                            </label>
                                        </div>
                                        <div class="col-md-6">
                                            <label>
                                                <span class="text-input"><input v-model="contactForm.email" name="email" type="text" placeholder="Email" required="required"></span>
                                            </label>
                                        </div>
                                        <div class="col-md-6">
                                            <label>
                                                <span class="text-input"><input v-model="contactForm.phone" name="phone" type="text" placeholder="Telephone" required="required"></span>
                                            </label>
                                        </div>
                                        <div class="col-md-6">
                                            <label>
                                                <span class="text-input"><input v-model="contactForm.subject" name="subject" type="text" placeholder="Sujet" required="required"></span>
                                            </label>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-lg-4">
                                    <label>
                                        <span class="text-input"><textarea v-model="contactForm.message" name="message" rows="4" placeholder="Message" required="required"></textarea></span>
                                    </label>
                                </div>
                                <div class="col-lg-12">
                                    <div class="pt-15 text-center">
                                        <button class="submit ttm-btn ttm-btn-size-md ttm-btn-shape-rounded ttm-btn-style-fill ttm-btn-color-skincolor" type="submit" :disabled="contactForm.status == 'Veuillez patienter...'">Envoyer!</button>
                                    </div>
                                </div>

                                <div class="col-lg-12 mt-5 text-center">
                                    {{ contactForm.status }}
                                </div>
                            </div>
                        </form>
                    </div>
                </div><!-- row end -->                
            </div>
        </section>
        <!-- conatact-section end -->
    </div>
</template>

<script>
import EmailMixin from '@/mixins/email'

export default {
    name: 'Contact',
    mixins: [ EmailMixin ],
    data() {
        return {
            contactForm: {
                name: '',
                email: '',
                phone: '',
                subject: '',
                message: '',
                status: ''
            },
        }
    },
    methods: {
        async submit(){

            if (this.contactForm.name && this.contactForm.email && this.contactForm.message){

                this.contactForm.status = 'Veuillez patienter...';

                let task = await this.sendBasicEmail(
                    this.contactForm.email, 
                    'Formulaire de contact', 
                    this.contactForm.subject || 'Message', 
                    this.contactForm.message + '<br><small>' + this.contactForm.phone + '</small>' + '<br><b>' + this.contactForm.name + '</b>'
                )

                this.contactForm.status = task ? 'Nous avons reçu votre message !' : 'Echec de l\'envoi';

                if (task) this.contactForm = { name: '', email: '', phone: '', subject: '', message: '', status: this.contactForm.status };

            }

        },
    }
}
</script>