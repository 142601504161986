<template>
    <Head>
        <html lang="fr-FR" />
        <title>{{ websiteInfo.title }}</title>
        
        <link rel="shortcut icon" :href="makeLink(websitePicsInfo.favicon)"/>
        <meta name="description" :content="websiteInfo.description"/>
        <meta name="keywords" :content="websiteInfo.metaKeys"/>
    </Head>
</template>

<script>
import { Head } from '@vueuse/head'
import InfoMixin from '@/mixins/info.js'

export default {
    mixins: [InfoMixin],
    components: {
        Head
    }
}
</script>