<template>
    <div class="site-main" id="about">
        <!--about-section-->
        <section class="ttm-row about-section clearfix" style="padding-bottom: 0">
            <div class="container">
                <div class="row">
                    <div class="col-lg-6">
                        <!-- ttm_single_image-wrapper -->
                        <div class="ttm_single_image-wrapper">
                            <el-carousel :interval="5000" type="card" arrow="always" :height="isMobile ? '300px' : '450px'">
                                <el-carousel-item v-for="(item, i) in aboutWidgetsData" :key="i">
                                    <div class="item">
                                        <img class="item__image" :src="makeLink(item.image)" alt="" />
                                    </div>
                                </el-carousel-item>
                            </el-carousel>
                        </div>
                    </div>
                    <div class="col-lg-6 col-xs-12">
                        <div class="pl-15 res-991-pl-0 res-991-pt-40">
                            <!-- section title -->
                            <div class="section-title">
                                <div class="title-header">
                                    <h5>{{ aboutWidgetData.uptitle || '' }}</h5>
                                    <h2 class="title">{{ aboutWidgetData.title || '' }}</h2>
                                </div>
                                <div class="heading-seperator"><span></span></div>
                            </div><!-- section title end -->
                            <p style="text-align: justify">{{ aboutWidgetData.text || '' }}</p>
                        </div>
                    </div>
                </div><!-- row end -->
            </div>
        </section>
        <!--about-section end-->
    </div>
</template>

<script>
import WidgetMixin from '@/mixins/widget'

import { useDevice } from "next-vue-device-detector"

export default {
    name: 'About',
    mixins: [ WidgetMixin ],
    data() {
      return {
        isMobile: false
      }
    },
    created() {
      const device = useDevice()

      this.isMobile = device.mobile
    },
    computed: {
        aboutWidgetData(){

            return this.getWidgetData('ABOUT')

        },
        aboutWidgetsData(){

            return this.getWidgetsData('ABOUT_ITEM')

        }
    }
}
</script>

<style scoped>
.item {
  position: relative;
  height: 100%;
}

.item__image {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.el-carousel__item:nth-child(2n) {
  background-color: #99a9bf;
}

.el-carousel__item:nth-child(2n + 1) {
  background-color: #d3dce6;
}

@media only screen and (max-width: 1199px){
  .item__image {
    width: 100%;
    height: 100%;
    object-fit: fill;
  }
}

@media (max-width: 991px){
  body .page.ttm-sidebar-true .site-main, .ttm-row {
    padding: 30px 0 !important;
  }
}
</style>