<template>
    <div class="site-main" id="showcase">
        
        <section class="ttm-row conatact-section clearfix" style="padding: 0">
            <div class="container">               
                <el-row :gutter="10">
                    <el-col :xs="24" :sm="24" :md="12" :lg="12" :xl="12" v-for="(item, i) in showcaseWidgetsData" :key="i">
                        
                        <el-image 
                            fit="fill"
                            append-to-body
                            :src="makeLink(item.image)"
                            style="width: 100%"                            
                            class="section-select-preview mt-10"                            
                            :preview-src-list="[makeLink(item.image)]">
                        </el-image>

                    </el-col>
                </el-row>        
            </div>
        </section>
        
    </div>
</template>

<script>
import WidgetMixin from '@/mixins/widget'

export default {
    name: 'Showcase',
    mixins: [ WidgetMixin ],
    computed: {
        showcaseWidgetsData(){

            return this.getWidgetsData('SHOWCASE_ITEM')

        }
    },
}
</script>

<style>

</style>