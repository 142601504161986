<template>
    <el-carousel :interval="5000" arrow="always" :height="isMobile ? '225px' : '650px'" id="slider">
        <el-carousel-item v-for="(item, i) in slidersWidgetData" :key="i">
            <div class="item">
                <img class="item__image" :src="makeLink(item.image)" alt="" />
            </div>
        </el-carousel-item>
    </el-carousel>
    <div class="text-center hide btp-btn">
        <a class="ttm-btn ttm-btn-size-sm ttm-btn-shape-rounded ttm-btn-style-border" :href="getBaseUrl() + '/btp'">BTP</a>
    </div>
    <div class="text-center mt-5">
      <h3>Agence groupe zénith</h3>
      <span style="color: #fdab24">TOGO &amp; Burkina Faso</span><br>
      <small>DEUX GRANDES AGENCES AUX SERVICES DE SES PARTENAIRES</small><br>
      <small>ZÉNITH COM LE RÉGIE PUBLICITAIRE N°1</small>
    </div>
</template>

<script>
import WidgetMixin from '@/mixins/widget'

import { useDevice } from "next-vue-device-detector"
import { getBaseUrl } from '@/utils/functions'

export default {
    name: 'Slider',
    mixins: [ WidgetMixin ],
    data() {
      return {
        isMobile: false
      }
    },
    created() {
      const device = useDevice()

      this.isMobile = device.mobile
    },
    computed: {
        slidersWidgetData(){

            return this.getWidgetsData('SLIDER_ITEM')

        }
    },
    methods: {
      getBaseUrl
    }
}
</script>

<style scoped>
.item {
  position: relative;
  height: 100%;
}

.item__image {
  width: 100%;
  height: 100%;
  object-fit: fill;
}

.el-carousel__item:nth-child(2n) {
  background-color: #99a9bf;
}

.el-carousel__item:nth-child(2n + 1) {
  background-color: #d3dce6;
}
</style>