<template>
    <div class="site-main" id="clients">
        
        <section class="ttm-row conatact-section clearfix" style="padding-bottom: 0">
            <div class="container">
                 <el-row :gutter="10">
                    <el-col :span="24" class="text-center">                         
                        <h3>Nos Clients</h3>
                    </el-col>
                </el-row>
                <el-row :gutter="10">
                    <el-col :span="24">
                        
                        <el-image 
                            fit="fill"
                            append-to-body
                            :src="makeLink(clientsWidgetData.image)"
                            style="width: 100%">
                        </el-image>

                    </el-col>
                </el-row>        
            </div>
        </section>
        
    </div>
</template>

<script>
import WidgetMixin from '@/mixins/widget'

export default {
    name: 'Clients',
    mixins: [ WidgetMixin ],
    computed: {
        clientsWidgetData(){

            return this.getWidgetData('CLIENTS_SECTION')

        }
    },
}
</script>

<style>

</style>