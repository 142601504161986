export default {
    computed: {
        widgetsJSONArray(){

            let w = []

            this.$_.forIn(this.widgetsJSONData, function(value, key) {
    
                self.w.push(value)
        
            })

            return w

        },
        uniqueWidgets(){

            return this.widgetsJSONArray.filter(w => !w.duplicable)

        },
        duplicableWidgets(){

            return this.widgetsJSONArray.filter(w => w.duplicable)

        },
        unpublishedWidgets(){

            return this.widgets.filter(w => !parseInt(w.display))
            
        },
        publishedWidgets(){

            return this.widgets.filter(w => parseInt(w.display))

        },        
    },
    methods: {
        getWidgetData(wid){

            let widget = this.publishedWidgets.find(d => d.wid == wid)

            if (widget) return JSON.parse(widget.data)

            return null

        },
        getWidgetsData(wid){

            let widgets = this.publishedWidgets.filter(w => w.wid == wid), widgetsData = []

            if (widgets && widgets.length) {
                
                widgets.forEach(widget => {

                    widgetsData.push(JSON.parse(widget.data))

                })

                return widgetsData
                
            }

            return []

        }
    }

}