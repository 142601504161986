<template>
    <div style="margin-top: 45px">
        <iframe :src="mapWidgetData.url" width="600" height="450" style="border:0;" allowfullscreen="" loading="lazy"></iframe>
    </div>
</template>

<script>
import WidgetMixin from '@/mixins/widget'

export default {
    name: 'Map',
    mixins: [ WidgetMixin ],
    computed: {
        mapWidgetData(){

            return this.getWidgetData('MAP')

        }
    }
}
</script>