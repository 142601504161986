<template>
    <div class="page">

        <HEAD/>

        <template v-if="currentPage">
            <div v-for="(section, i) in sectionsArray(currentPage.top_sections)" :key="i">
                <component :is='section'></component>
            </div>
            <div v-if="currentPage.content">
                <template v-html="currentPage.content" />
            </div>
            <div v-for="(section, i) in sectionsArray(currentPage.bottom_sections)" :key="i">
                <component :is='section'></component>
            </div>
        </template>

    </div>
</template>

<script>
import HEAD from '@/components/sections/base/Head.vue'
import HEADER from '@/components/sections/Header.vue'
import SLIDER from '@/components/sections/Slider.vue'
import SHOWCASE from '@/components/sections/Showcase.vue'
import CLIENTS from '@/components/sections/Clients.vue'
import ABOUT from '@/components/sections/About.vue'
import SERVICES from '@/components/sections/Services.vue'
import PROJECTS from '@/components/sections/Projects.vue'
import CONTACT from '@/components/sections/Contact.vue'
import MAP from '@/components/sections/Map.vue'
import FOOTER from '@/components/sections/Footer.vue'

import PageMixin from '@/mixins/page'

export default {
    name: 'Container',
    mixins: [ PageMixin ],    
    components: {
        HEAD, 
        HEADER,
        SLIDER,
        SHOWCASE,
        ABOUT, 
        SERVICES,
        PROJECTS,
        CONTACT,
        CLIENTS,
        MAP,
        FOOTER
    },
    mounted() {
        
        const scripts = [
            "js/jquery.min.js",
            "js/tether.min.js",
            "js/bootstrap.min.js",
            "js/jquery.easing.js",
            "js/jquery-waypoints.js",
            "js/jquery-validate.js",
            "js/jquery.prettyPhoto.js",
            "js/slick.min.js",
            "js/numinate.min.js",
            "js/imagesloaded.min.js",
            "js/jquery-isotope.js",
            "js/main.js"
        ]

        scripts.forEach(script => {
            let tag = document.head.querySelector(`[src="${ script }"`)
            if (!tag) {
                tag = document.createElement("script")
                tag.setAttribute("src", process.env.BASE_URL + script)
                tag.setAttribute("type", 'text/javascript')
                document.head.appendChild(tag)
            }
        })

    },
}
</script>