import { MenuItemTypes } from '@/utils/enums'

export default {
    data() {
        return {
            currentMenuItem: null
        }
    },
    computed: {
        currentMenu() {

            if (this.menus && this.menus.length) return this.menus[0]
            else return null

        },
        currentParentMenuItems() {

            if (this.currentMenu && this.menuItems) {
                let items = this.menuItems.filter(item => item.menu == this.currentMenu.id && !parseInt(item.parent) && parseInt(item.display))

                items = this.orderMenuItems(items)

                this.currentMenuItem = items[0]

                return items
            }
            else return []

        },
    },
    methods: {
        currentChildrenMenuItems(parent) {

            if (this.currentMenu && this.menuItems) {

                let items = this.menuItems.filter(item => item.menu == this.currentMenu.id && item.parent == parent && parseInt(item.display))

                return this.orderMenuItems(items)

            }
            else return []

        },
        orderMenuItems(items) {

            let orderedItems = this.$_.sortBy(items, function(obj) {
                
                return parseInt(obj.position, 10)

            })

            orderedItems.forEach(item => {

                switch (parseInt(item.type)) {
                    case MenuItemTypes.INTERNAL_LINK:
                        item.href = '#'
                        break;
                
                    case MenuItemTypes.EXTERNAL_LINK:
                        item.href = '#'
                        break;

                    case MenuItemTypes.MEGA:
                        item.href = '#'
                        break;

                    case MenuItemTypes.SECTION:
                        item.href = `#${item.target.toLowerCase()}`
                        break;
                }

            })

            return orderedItems

        },
        setCurrentMenuItem(item) {

            this.currentMenuItem = item

        },
    },
}