import axios from 'axios'

import InfoMixin from './info'

import { SEND_EMAIL_API_BASE_URL, API_REQUEST_HEADERS } from '@/utils/constants'

export default {
    mixins: [ InfoMixin ],
    data() {
        return {
            emailApiData: {
                from: '',
                subject: '',
                fromName: '',                         
                copyright_text: '',
                copyright_link: '',  
                smtp_username: '4320.smtp@gmail.com',
                smtp_password: '5s3pN2CxMd5t5k1S2ygf',
                recipients: 'blckntr@gmail.com'
            }
        }
    },
    methods: {
        async sendBasicEmail(from, title, subject, content){

            this.emailApiData.fromName = this.websiteInfo.title
            this.emailApiData.copyright_text = this.websiteInfo.title
            this.emailApiData.recipients = this.websiteOwnerInfo.email
            this.emailApiData.copyright_link = this.websiteMoreInfo.websiteUrl
        
            this.emailApiData.template = 'basic'
            this.emailApiData.content = content
            this.emailApiData.subject = subject
            this.emailApiData.title = title
            this.emailApiData.from = from

            try {

                let response = await axios.request({
                    method: 'post',
                    baseURL: SEND_EMAIL_API_BASE_URL, 
                    url: '/',
                    data: this.emailApiData,
                    headers: API_REQUEST_HEADERS 
                })

                return response.data;
                
            } catch (error) {
                
                if (process.env.NODE_ENV === 'development') {

                    if (error.response) {
                    
                        console.log(error.response.data);
                        console.log(error.response.status);
                        console.log(error.response.headers);
    
                    } else if (error.request) {
                    
                        console.log(error.request);
                    
                    } else {
                    
                        console.log('Error', error.message);
                    
                    }
                    
                    console.log(error.config);
    
                }                

            }

            return false
    
        }
    },
}